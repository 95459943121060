<script>
  import Vue from 'vue';
  import BaseVue from '@frontend/Base.vue';
  import Gen from '../helper/Gen';
  Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

  export default {
    extends: BaseVue,
    data() {
       return {
          data:[],
          product:[],
          newsTags:[],
          dataCount:0,
       }
    },
    computed: {

    },
    async mounted() {
      this.$set(this.$root, 'page', this)
      this.getContent()
    },
    methods: {
      getContent(){
        Gen.apirest("/search", Gen.clone(this.$route.query),(err,resp)=>{
          if(err) console.log("About goodness error "+err)
          this.data = resp.data
          this.product = resp.product
          this.newsTags = resp.newsTags
          this.dataCount = resp.dataCount
          setTimeout(()=>{
            AOS.init({ once: true });
          },500)
        })
      },
      changePage(page=1){
        this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
      }
    },
    watch: {
      $route() {
        this.getContent()
      }
    },
  };
</script>
<template>
  <section id="content">
      <div class="content-wrap py-0 pb-3">
        <section id="page-title" class="page-title-mini">
          <div class="container clearfix">
            <h1>{{web.menu_search}}</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">{{web.menu_search}}</li>
            </ol>
          </div>
        </section>
        <section class="section nobg">
          <div class="container">
                <!-- No Result -->
                <div class="notfound-search" v-if="data.data.length == 0">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <img :src="assets('fo_images','/search-no-result.png')" alt="notfound" class="notfound-search__img">
                      <h3 class="search-result__title mb-2">{{web.lbl_serach_not_found+' "'+$route.query.q+'"'}}</h3>
                      <p class="notfound-search__caption mb-2">{{web.lbl_serach_try}}</p>
                      <div class="text-center mb-4">
                        <router-link :to="{path:'/search-result',query:{q:n}}" v-for="(n,k) in newsTags" :key="k" class="not-found-refer-links__link">{{n}}</router-link>
                      </div>
                      <router-link :to="{name:'Index'}" class="cta cta-primary cta--reveal cta--longer">
                        <span>{{web.lbl_serach_back_to_home}}</span>
                        <i class="icon-line-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              <!-- Ditemukan -->
                <div class="search-result" v-else>
                  <h3 class="search-result__title" v-if="data.data.length > 0 && product.length == 0">{{dataCount+' '+web.lbl_serach_article_count+' "'+$route.query.q+'"'}}</h3>
                  <h3 class="search-result__title" v-else-if="data.data.length == 0 && product.length > 0">{{product.length+' '+web.lbl_serach_product_count+' "'+$route.query.q+'"'}}</h3>
                  <h3 class="search-result__title" v-else >{{dataCount+' '+web.lbl_serach_article_and+' '+product.length+' '+web.lbl_serach_product_count+' "'+$route.query.q+'"'}}</h3>
                  <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">

                      <!-- no article sugest -->
                        <div class="notfound-search" v-if="data.data.length==0">
                          <div class="row justify-content-center">
                            <div class="col-lg-10">
                              <img :src="assets('fo_images','/search-no-result.png')" alt="notfound" class="notfound-search__img">
                              <p class="notfound-search__caption mb-2">{{web.lbl_serach_article_not_found+' "'+$route.query.q+'". '+lbl_serach_article_not_found_try}}</p>
                              <div class="text-center mb-4">
                                <router-link :to="{path:'/search-result',query:{q:n}}" v-for="(n,k) in newsTags" :key="k" class="not-found-refer-links__link">{{n}}</router-link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- have article -->
                        <div v-for="(v,k) in data.data" :key="k" class="mt-1">
                          <router-link v-if="v.an_type=='I'" :to="{name:'HealthInfoDetail',params:{cat:v.aic_slug,slug:v.an_slug}}" class="search-result-post mt-3">
                            <div class="row align-items-center">
                              <div class="col-md-12">
                                <div class="wrap_article_result">
                                     <div class="row align-items-center">
                                      <div class="col-md-6">
                                      <div class="search-result-post__img">
                                      <img :src="uploader(v.an_img)" :alt="v.an_title">
                                      </div>
                                      </div>
                                      <div class="col-md-6">
                                      <div class="search-result-post-desc">
                                      <span class="article-desc__label">{{v.an_type == 'N' ? 'News & Event' :'Health Info'}}</span>
                                      <h3 class="search-result-post-desc__title">{{v.an_title}}</h3>
                                      <span class="article-desc__more">Read More <i class="icon-line-arrow-right"></i></span>
                                      </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </router-link>
                          <router-link  v-else :to="{name:'NewsEventDetail',params:{slug:v.an_slug}}" class="search-result-post">
                            <div class="row align-items-center">
                              <div class="col-md-12">
                                <div class="wrap_article_result">
                                     <div class="row align-items-center">
                                      <div class="col-md-6">
                                      <div class="search-result-post__img">
                                      <img :src="uploader(v.an_img)" :alt="v.an_title">
                                      </div>
                                      </div>
                                      <div class="col-md-6">
                                      <div class="search-result-post-desc">
                                      <span class="article-desc__label">{{v.an_type == 'N' ? 'News & Event' :'Health Info'}}</span>
                                      <h3 class="search-result-post-desc__title">{{v.an_title}}</h3>
                                      <span class="article-desc__more">Read More <i class="icon-line-arrow-right"></i></span>
                                      </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-10">
                     <!-- no product -->
                        <div class="notfound-search mb-0 mt-4 mt-lg-0" v-if="product.length == 0">
                          <img :src="assets('fo_images','/search-no-products.png')" class="notfound-search__img notfound-search__img--products" alt="Tidak ditemukan hasil untuk produk">
                          <span class="notfound-search__title">{{web.lbl_serach_product_not_found_try+' "'+$route.query.q+'"'}}</span>
                        </div>
                     <!-- have products -->
                        <div v-else class="row align-items-end">
                          <div class="col-6 col-md-4 col-lg-12" v-for="(vp,kp) in product" :key="kp">
                            <div class="product_wrapper pad_10">
                              <div class="row align-items-center">
                                <div class="col-lg-6">
                                  <router-link :to="{name:'ProductDetail',params:{cat:vp.apc_slug,slug:vp.ap_slug}}" class="s-prod-img">
                                    <img :src="uploader(vp.ap_img)" alt="Clients">
                                  </router-link>
                                </div>
                                <div class="col-lg-6">
                                  <div class="product_desc">
                                    <h3 class="text-lg-left">{{vp.ap_name}}</h3>
                                    <router-link :to="{name:'ProductDetail',params:{cat:vp.apc_slug,slug:vp.ap_slug}}" class="cta cta-primary cta--reveal mw-auto d-none d-md-block cta--small mt-3">
                                      <span>Lihat Detail</span><i class="icon-line-arrow-right"></i>
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                  </div>
                  <nav class="mt_pagination text-center">
                    <Pagination :limit="2" :data="data" @pagination-change-page="changePage" class="pagination justify-content-center">
                      <i slot="prev-nav" class="icon-angle-left"></i>
                      <i slot="next-nav" class="icon-angle-right"></i>
                    </Pagination>
                  </nav>
                </div>
          </div>
        </section>
      </div>
    </section>
</template>